import C from "../../components/sidebar/StandardSidebar.module.css";

import { datetimeToDate } from "@warrenio/api-spec/conversion";
import { localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { PriceBlock } from "../../components/sidebar/PriceBlock.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { StandardBlock } from "../../components/sidebar/StandardSidebar.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceCreateLinks.ts";
import { serviceLink } from "./links.ts";
import { ServiceOsImageIcon } from "./os/ServiceOsImageIcon.tsx";

import type { ManagedServicesVmBody } from "@warrenio/api-spec/spec.oats.gen";
import { allAssignedServicesQueryAtom, type ManagedServiceWithAssigned } from "./joinAssignedQuery.ts";
import type { ManagedServiceLoc } from "./servicesQuery.ts";

const sortFields = [
    {
        id: "display_name",
        title: "Name",
        compare: localeCompareBy((i) => i.display_name),
    },
    { id: "created_at", title: "Created Date", getValue: (i) => datetimeToDate(i.created_at).getTime() },
    { id: "location", title: "Location", getValue: (i) => i.properties.location },
] satisfies SimpleSortField<ManagedServiceLoc>[];

export function ServicesList() {
    return (
        <SidebarList
            data={(props) => <ServicesData {...props} />}
            title="Services"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Service",
                action: ResourceCreateLinks.managed_service,
            }}
            listId="services"
        />
    );
}

export default ServicesList;

function ServicesData({ queryString, sortId, sortAsc }: SidebarListState) {
    const data = useSuspenseQueryAtom(allAssignedServicesQueryAtom);

    let serviceItems = getFilteredItems(queryString, [...data.values()], (item) => [item.display_name]);
    serviceItems = getSortedItems(sortFields, { sortId, sortAsc }, serviceItems);

    const servicesBlocks = serviceItems.map((obj) => <ServiceBlock key={obj.uuid} item={obj} />);

    const empty = <EmptyItem icon="jp-services-icon">Create a new service and get it running in minutes.</EmptyItem>;

    return servicesBlocks.length ? servicesBlocks : queryString ? <NoResults /> : empty;
}

export function ServiceBlock({ item }: { item: ManagedServiceWithAssigned }) {
    const { display_name, service, resources, properties, assignedPublicIp } = item;

    function ItemStatus() {
        if (service === "kubernetes") {
            const { spec, status } = properties;
            const { hibernation } = spec;
            if (hibernation && status) {
                if (hibernation.enabled && status.hibernated) {
                    return <div className="color-muted text-uppercase">Hibernated</div>;
                }

                if (hibernation.enabled && !status.hibernated) {
                    return <div className="color-muted text-uppercase">Hibernating</div>;
                }

                if (!hibernation.enabled && status.hibernated) {
                    return <div className="color-muted text-uppercase">Waking up</div>;
                }
            }
        }

        let runningVMs = 0;
        let stoppedVMs = 0;

        const vms = resources.filter((res: ManagedServicesVmBody) => res.resource_type === "vm");
        for (const { resource_allocation } of vms) {
            if (resource_allocation && resource_allocation.status === "running") {
                runningVMs++;
            }
            if (resource_allocation && resource_allocation.status !== "running") {
                stoppedVMs++;
            }
        }

        return (
            <div>
                {runningVMs > 0 && <div className="text-success text-uppercase">{runningVMs} running</div>}
                {stoppedVMs > 0 && <div className="text-error text-uppercase">{stoppedVMs} stopped</div>}
            </div>
        );
    }

    return (
        <StandardBlock link={serviceLink(item)}>
            <div className={C.StandardBlockIcon}>
                <ServiceOsImageIcon os_name={service} className="size-0.875rem" />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{display_name}</b>
                <div className="text-ellipsis color-primary">
                    {service === "kubernetes" ? (
                        properties.location
                    ) : (
                        <>
                            {assignedPublicIp?.address ?? properties.service_ip}{" "}
                            {!!properties.location && <> / {properties.location}</>}
                        </>
                    )}
                </div>
            </div>

            <div className={C.StandardBlockType}>
                <ItemStatus />
            </div>

            <PriceBlock prefix={service === "kubernetes" ? "from" : ""} price={item.price} />
        </StandardBlock>
    );
}
